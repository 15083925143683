import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesHandlingService } from '@app/services';
import { selectRouteParam } from '@app/store';
import { transferParamName } from '@data/constants';
import { Routes } from '@data/enums';
import { Store } from '@ngrx/store';
import { getStoreValue } from '@shared/helpers';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AcceptTransferredPromoGuard  {
  constructor(private cookies: CookiesHandlingService, private router: Router, private store: Store) {}

  canActivate(): boolean {
    if (
      this.cookies?.cookiesExist() &&
      !getStoreValue(this.store.select(selectRouteParam(transferParamName)).pipe(filter((id): id is string => !!id)))
    ) {
      this.router.navigate(['/', Routes.DASHBOARD]);
      return false;
    }

    if (!navigator.cookieEnabled) {
      this.router.navigate(['/', Routes.COOKIES, Routes.DISABLED]);
    }

    return true;
  }
}
