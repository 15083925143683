import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesHandlingService, NotificationsService, UiMessagesApiService } from '@app/services';
import {
  acceptCookiesParamName,
  transferParamName,
  uiMessagesAcceptCookiesParamName,
  uiMessagesTransferParamName,
} from '@data/constants';
import { Routes } from '@data/enums';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, delayWhen, EMPTY, filter, map, Observable, of, skipWhile, switchMap } from 'rxjs';
import { selectRouteParam, selectUrl } from '../router/router.selectors';
import { fetchUiMessages, loadUiMessages, loadUiMessagesFail, loadUiMessagesSuccess } from './ui-messages.actions';
import { selectUiMessagesLoaded } from './ui-messages.selectors';

@Injectable()
export class UiMessagesEffects {
  fetchUiMessages$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchUiMessages),
      concatLatestFrom(() => this.store.select(selectUiMessagesLoaded)),
      switchMap(([, loaded]) => (!loaded ? of(loadUiMessages()) : EMPTY))
    );
  });

  loadUiMessages$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUiMessages),
      // TODO: Why does this take so long and is it still needed?
      // Delay till selectUrl is not undefined
      delayWhen(() => this.store.select(selectUrl).pipe(filter((url): url is string => !!url))),
      concatLatestFrom(() => this.store.select(selectUrl)),
      switchMap(([, url]) => {
        // Defaults to Cookies Routing Details
        let paramName = acceptCookiesParamName;
        let tokenParamName = uiMessagesAcceptCookiesParamName;
        let urlIncluded = url?.includes(Routes.COOKIES);

        if (!urlIncluded) {
          paramName = transferParamName;
          tokenParamName = uiMessagesTransferParamName;
          urlIncluded = url?.includes(Routes.TRANSFER);
        }

        return of({ paramName, tokenParamName, urlIncluded });
      }),
      concatLatestFrom(({ paramName }) => this.store.select(selectRouteParam(paramName))),
      skipWhile(([{ urlIncluded }, id]) => urlIncluded && !id),
      switchMap(([{ tokenParamName }, id]) =>
        this.api.getMessages(id, tokenParamName).pipe(
          map(data => loadUiMessagesSuccess({ data })),
          catchError((error: HttpErrorResponse) => of(loadUiMessagesFail(error)))
        )
      )
    );
  });

  loadUiMessagesFail$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loadUiMessagesFail),
        concatLatestFrom(() => this.store.select(selectUrl)),
        map(([{ error }, url]) => {
          if (!url?.includes(Routes.COOKIES)) {
            this.notify.showError(error);
            const route = this.cookies.cookiesExist() ? Routes.DASHBOARD : Routes.PAGE_NOT_FOUND;
            this.router.navigate(['/', route]);
          }
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private api: UiMessagesApiService,
    private cookies: CookiesHandlingService,
    private notify: NotificationsService,
    private router: Router,
    private store: Store
  ) {}
}
