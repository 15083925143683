import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiMessagesService } from '@app/services';
import { MessageTypes } from '@data/enums';
import { MessageFillinValues, Promotion } from '@data/models';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './transfer-confirmation.component.html',
  styleUrls: ['./transfer-confirmation.component.scss'],
})
export class TransferConfirmationDialogComponent {
  readonly messageTypes = MessageTypes;

  readonly message$!: Observable<string>;
  readonly title$!: Observable<string>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Promotion,
    private messages: UiMessagesService
  ) {
    this.message$ = this.messages.getMessage(MessageTypes.DASH_TRANS_MSG);
    this.title$ = this.messages.getMessage(MessageTypes.DASH_TRANS_TITLE);
  }

  get promoName(): MessageFillinValues {
    const promo = this.data?.name;
    return { promo };
  }
}
