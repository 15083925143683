import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  // constructor(private store: Store) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // TODO Implement equivalent for this app. Need to discuss with Vadym
        // if (error.status === 401) {
        //   // auto logout if 401 response returned from api
        //   this.store.dispatch(logout());
        // }

        // TODO Fix deprecation
        // Error Message: The signature '(error: any): Observable<never>' of 'throwError' is deprecated. typescript:6387
        return throwError(error);
      })
    );
  }
}
