<h2 mat-dialog-title>{{ title$ | ngrxPush }}</h2>

<mat-dialog-content *ngrxLet="message$ as message">
  <p class="text-base font-normal">
    {{ message | fillInMessage: promoName }}
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <vf-button class="w-full m-0" [messageType]="messageTypes.DIALOG_OK_BTN" mat-dialog-close></vf-button>
</mat-dialog-actions>
