import { fallbackMessages } from '@data/constants';
import { initListState, ListStore, Messages, UiMessage } from '@data/models';
import { createReducer, on } from '@ngrx/store';
import { loadListFailFunction, loadListFunction } from '@shared/helpers';
import { loadUiMessages, loadUiMessagesFail, loadUiMessagesSuccess } from './ui-messages.actions';

export interface UiMessagesState extends ListStore<UiMessage> {
  messageObj: Messages;
}

export const initialState: UiMessagesState = { ...initListState, messageObj: fallbackMessages };

export const uiMessagesReducer = createReducer(
  initialState,
  on(loadUiMessages, loadListFunction()),
  on(
    loadUiMessagesSuccess,
    (state: UiMessagesState, { data }): UiMessagesState => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      data,
      messageObj: generateMessageObject(data),
    })
  ),
  on(loadUiMessagesFail, loadListFailFunction())
);

const generateMessageObject = (data: UiMessage[]): Messages => {
  const rv = {} as Messages;

  try {
    data.forEach(({ messageType, message }) =>
      Object.defineProperty(rv, messageType, {
        value: message,
        writable: false,
      })
    );
  } catch (err) {
    console.error('generateMessageObject error', err);
  }

  return rv;
};
