import { fallbackMessages } from '@data/constants';
import { MessageTypes } from '@data/enums';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UiMessagesState } from './ui-messages.reducer';

export const selectUiMessagesState = createFeatureSelector<UiMessagesState>('uiMessages');

export const selectUiMessages = createSelector(selectUiMessagesState, (state: UiMessagesState) => state.data);

export const selectUiMessagesObj = createSelector(selectUiMessagesState, (state: UiMessagesState) => state.messageObj);

export const selectUiMessagesLoading = createSelector(selectUiMessagesState, (state: UiMessagesState) => state.loading);

export const selectUiMessagesLoaded = createSelector(selectUiMessagesState, (state: UiMessagesState) => state.loaded);

export const selectUiMessagesError = createSelector(selectUiMessagesState, (state: UiMessagesState) => state.error);

export const selectSpecificMessageType = (type: MessageTypes) =>
  createSelector(selectUiMessagesState, ({ messageObj }: UiMessagesState) =>
    messageObj ? messageObj[type] ?? fallbackMessages[type] : fallbackMessages[type]
  );
