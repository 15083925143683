import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesHandlingService } from '@app/services';
import { Routes } from '@data/enums';

@Injectable({ providedIn: 'root' })
export class AccessCookieExistsGuard  {
  constructor(private cookies: CookiesHandlingService, private router: Router) {}

  canActivate(): boolean {
    if (!navigator.cookieEnabled) {
      this.router.navigate(['/', Routes.COOKIES, Routes.DISABLED]);
      return false;
    }
    if (!this.cookies?.cookiesExist()) {
      this.router.navigate(['/', Routes.COOKIES]);
      return false;
    }
    return true;
  }
}
