import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiMessagesService } from '@app/services';
import { MessageTypes } from '@data/enums';
import { NewOrder } from '@data/models';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './new-order-confirmation.component.html',
  styleUrls: ['./new-order-confirmation.component.scss'],
})
export class NewOrderConfirmationDialogComponent {
  readonly messageTypes = MessageTypes;

  readonly message$!: Observable<string>;
  readonly title$!: Observable<string>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: NewOrder,
    private messages: UiMessagesService
  ) {
    this.message$ = this.messages.getMessage(MessageTypes.DASH_NEW_ORDER_DIALOG_MSG);
    this.title$ = this.messages.getMessage(MessageTypes.DASH_NEW_ORDER_DIALOG_TITLE);
  }
}
