import { HttpErrorResponse } from '@angular/common/http';
import { UiMessage } from '@data/models';
import { createAction, props } from '@ngrx/store';

export enum UiMessagesActionTypes {
  FETCH = '[UI Messages] Fetch UI Messages',
  LOAD = '[UI Messages] Load UI Messages',
  LOAD_SUCCESS = '[UI Messages] Load UI Messages Success',
  LOAD_FAIL = '[UI Messages] Load UI Messages Fail',
}

export const fetchUiMessages = createAction(UiMessagesActionTypes.FETCH);
export const loadUiMessages = createAction(UiMessagesActionTypes.LOAD);
export const loadUiMessagesSuccess = createAction(UiMessagesActionTypes.LOAD_SUCCESS, props<{ data: UiMessage[] }>());
export const loadUiMessagesFail = createAction(UiMessagesActionTypes.LOAD_FAIL, props<{ error: HttpErrorResponse }>());
