import { HttpErrorResponse } from '@angular/common/http';
import { CookieData, NewOrder, Promotion } from '@data/models';
import { createAction, props } from '@ngrx/store';

export enum SessionActionTypes {
  ACCEPT_ORDER = '[Session] Accept New Order',
  ACCEPT_ORDER_FAIL = '[Session] Accept New Order Fail',
  ACCEPT_ORDER_SUCCESS = '[Session] Accept New Order Success',
  ADD_ORDER = '[Session] Add New Order to Existing Cookie',
  ADD_ORDER_FAIL = '[Session] Add New Order to Existing Cookies Fail',
  ADD_ORDER_SUCCESS = '[Session] Add New Order to Existing Cookies Success',
  SET_COOKIES = '[Session] Set Cookies',
  SET_COOKIES_SUCCESS = '[Session] Set Cookies Success',
  SET_COOKIES_FAIL = '[Session] Set Cookies Fail',
}

export const acceptOrder = createAction(
  SessionActionTypes.ACCEPT_ORDER,
  props<{ orderId: string; accepted: boolean }>()
);
export const acceptOrderFail = createAction(
  SessionActionTypes.ACCEPT_ORDER_FAIL,
  props<{ error: HttpErrorResponse }>()
);
export const acceptOrderSuccess = createAction(SessionActionTypes.ACCEPT_ORDER_SUCCESS);

export const addNewOrder = createAction(SessionActionTypes.ADD_ORDER);
export const addNewOrderFail = createAction(SessionActionTypes.ADD_ORDER_FAIL, props<{ error: HttpErrorResponse }>());
export const addNewOrderSuccess = createAction(
  SessionActionTypes.ADD_ORDER_SUCCESS,
  props<{ orderId: string; newOrder: NewOrder }>()
);

export const setCookies = createAction(
  SessionActionTypes.SET_COOKIES,
  props<{ data: CookieData; transferredPromo?: Promotion }>()
);
export const setCookiesSuccess = createAction(
  SessionActionTypes.SET_COOKIES_SUCCESS,
  props<{ cookieExist: boolean; transferredPromo?: Promotion }>()
);
export const setCookiesFail = createAction(SessionActionTypes.SET_COOKIES_FAIL);
