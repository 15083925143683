import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesHandlingService } from '@app/services';
import { addNewOrder } from '@app/store';
import { Routes } from '@data/enums';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
// TODO - Future Task: Refactor to use CanActivateFn (Docs: https://angular.io/api/router/CanActivateFn);
export class AcceptCookiesGuard {
  constructor(
    private cookies: CookiesHandlingService,
    private router: Router,
    private store: Store
  ) {}

  canActivate(): boolean {
    if (this.cookies?.cookiesExist()) {
      this.store.dispatch(addNewOrder());
    }

    if (!navigator.cookieEnabled) {
      this.router.navigate(['/', Routes.COOKIES, Routes.DISABLED]);
    }

    return true;
  }
}
