export enum Routes {
  COOKIES = 'purchase', // Used for New Orders
  DASHBOARD = 'dashboard',
  DENIED = 'denied',
  DISABLED = 'disabled',
  HELP = 'help',
  NO_ORDER = 'no-order',
  PROMO_CODE = 'promo-code',
  PAGE_NOT_FOUND = '404',
  TRANSFER = 'transfer',
}
