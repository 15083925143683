export enum MessageTypes {
  COMPLETED_ERR = 'Error.OrderAlreadyCompleted',
  COOKIES_ACCEPT_BTN = 'Page.AcceptCookie.AcceptButton',
  COOKIES_DENY_BTN = 'Page.AcceptCookie.DenyButton',
  COOKIES_DENY_CONFIRM = 'Page.AcceptCookie.DenyConfirmation',
  COOKIES_DENY_TITLE = 'Page.AcceptCookie.DenyTitle',
  COOKIES_THANK_YOU = 'Page.AcceptCookie.ThankYou',
  COOKIES_DENIED_INST = 'Page.CookieDenied.Instruction',
  DASH_NEW_ORDER_DIALOG_MSG = 'Page.MyPromos.Dialog.NewOrder.Message',
  DASH_NEW_ORDER_DIALOG_TITLE = 'Page.MyPromos.Dialog.NewOrder.Title',
  DASH_TITLE = 'Page.MyPromos.Title',
  DASH_GET_QR_BTN = 'Page.MyPromos.GetQrCodeButton',
  DASH_PROMO_NUM = 'Page.MyPromos.PromoNumber',
  DASH_SCAN_INST = 'Page.MyPromos.ScanInstruction',
  DASH_TRANS_BTN = 'Page.MyPromos.TransferButton',
  DASH_TRANS_MSG = 'Page.MyPromos.TransferCompleted.Message',
  DASH_TRANS_TITLE = 'Page.MyPromos.TransferCompleted.Title',
  DIALOG_CANCEL_BTN = 'Dialog.CancelButton',
  DIALOG_NO_BTN = 'Dialog.NoButton',
  DIALOG_OK_BTN = 'Dialog.OkButton',
  DIALOG_YES_BTN = 'Dialog.YesButton',
  EXP_PROMO_ERR = 'Error.ExpiredPromo',
  INV_COOKIE_ERR = 'Error.InvalidCookieFile',
  INV_CRED_ERR = 'Error.InvalidCredentials',
  INV_DATA_ERR = 'Error.InvalidData',
  INV_EXP_QR_ERR = 'Error.InvalidExpiredQr',
  INV_ORDER_ERR = 'Error.InvalidOrder',
  INV_PROMO_ERR = 'Error.InvalidPromo',
  INV_QR_ERR = 'Error.InvalidQr',
  INV_TRANSFER_ERR = 'Error.InvalidPromoOrTransferCompleted',
  NO_PROMOS_ERR = 'Error.OrderHasNoPromotions',
  NOT_FOUND_MSG = 'Page.NotFound.Message',
  NOT_FOUND_REDIRECT_BTN = 'Page.NotFound.RedirectButton',
  NOT_FOUND_TITLE = 'Page.NotFound.Title',
  SCAN_INST = 'Page.ScanCode.Instruction',
  SCAN_TIME = 'Page.ScanCode.Time',
  SCAN_TITLE = 'Page.ScanCode.Title',
  TRANS_RECP_ACCEPT_BTN = 'Page.Transfer.Recipient.AcceptButton',
  TRANS_RECP_CANCEL_BTN = 'Page.Transfer.Recipient.CancelButton',
  TRANS_RECP_DENIED_MSG = 'Page.Transfer.Recipient.DeniedMessage',
  TRANS_RECP_INST = 'Page.Transfer.Recipient.Instruction',
  TRANS_RECP_TITLE = 'Page.Transfer.Recipient.Title',
  TRANS_SEND_CANCEL_BTN = 'Page.Transfer.Sender.CancelButton',
  TRANS_SEND_COPY_BTN = 'Page.Transfer.Sender.CopyButton',
  TRANS_SEND_INST = 'Page.Transfer.Sender.Instruction',
  TRANS_SEND_SHARE_BTN = 'Page.Transfer.Sender.ShareButton',
  TRANS_SEND_TITLE = 'Page.Transfer.Sender.Title',
  WEB_GEN_ERR = 'Web.GenericError',
  WEB_TITLE = 'Web.Title',
}
