import { RouterReducerState, BaseRouterStoreState, routerReducer } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { SessionActionTypes } from './session/session.actions';
import { SessionEffects } from './session/session.effects';
import { sessionReducer, SessionState } from './session/session.reducer';
import { UiMessagesEffects } from './ui-messages/ui-messages.effects';
import { uiMessagesReducer, UiMessagesState } from './ui-messages/ui-messages.reducer';

export interface AppState {
  session: SessionState;
  uiMessages: UiMessagesState;
  router: RouterReducerState<BaseRouterStoreState>;
}

export const reducers: ActionReducerMap<AppState> = {
  session: sessionReducer,
  uiMessages: uiMessagesReducer,
  router: routerReducer,
};

export const effects = [SessionEffects, UiMessagesEffects];

// TODO Are these the correct action types to use here
export const resetState =
  (reducer: ActionReducer<AppState>) =>
  (state: AppState | undefined, action: Action): AppState =>
    reducer(action.type === SessionActionTypes.SET_COOKIES_FAIL ? ({} as AppState) : state, action);

export const metaReducers: MetaReducer<AppState>[] = [resetState];

export * from './router/router.selectors';
export * from './session/session.actions';
export * from './session/session.selectors';
export * from './ui-messages/ui-messages.actions';
export * from './ui-messages/ui-messages.selectors';
