/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorResponse } from './common';

export interface BaseStoreState {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponse | null;
}

export interface ListStore<T> extends BaseStoreState {
  data: T[];
}

export interface EntityStore<T> extends BaseStoreState {
  data: T;
}

export const initListState: ListStore<any> = {
  data: [],
  loading: false,
  loaded: false,
  error: null,
};

export const initEntityState: EntityStore<any> = {
  data: null,
  loading: false,
  loaded: false,
  error: null,
};
