import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookiesHandlingService, NewOrderApiService, NotificationsService } from '@app/services';
import { acceptCookiesParamName } from '@data/constants';
import { Routes } from '@data/enums';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { NewOrderConfirmationDialogComponent, TransferConfirmationDialogComponent } from '@shared/dialogs';
import { catchError, delayWhen, filter, map, Observable, of, switchMap } from 'rxjs';
import { selectRouteParam } from '../router/router.selectors';
import * as SessionActions from './session.actions';
import { selectUserSessionNewOrder } from './session.selectors';

@Injectable()
export class SessionEffects {
  acceptOrder$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.acceptOrder),
      switchMap(({ orderId, accepted }) =>
        this.api.acceptNewOrder(orderId, accepted).pipe(
          map(data => SessionActions.setCookies({ data })),
          catchError(error => of(SessionActions.acceptOrderFail({ error })))
        )
      )
    );
  });

  addNewOrder$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.addNewOrder),
      delayWhen(() => this.store.select(selectRouteParam(acceptCookiesParamName)).pipe(filter(orderId => !!orderId))),
      switchMap(() => this.store.select(selectRouteParam(acceptCookiesParamName))),
      filter((orderId): orderId is string => !!orderId),
      switchMap(orderId =>
        this.api.getNewOrderInfo(orderId).pipe(
          map(newOrder => SessionActions.addNewOrderSuccess({ orderId, newOrder })),
          catchError(error => of(SessionActions.addNewOrderFail({ error })))
        )
      )
    );
  });

  addNewOrderSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.addNewOrderSuccess),
      map(({ orderId }) => SessionActions.acceptOrder({ orderId, accepted: true }))
    );
  });

  setCookies$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.setCookies),
      map(({ data, transferredPromo }) => ({
        cookieCode: data.cookieCode,
        expDate: new Date(data.expDate),
        cookieExist: this.cookies.cookiesExist(),
        transferredPromo,
      })),
      switchMap(({ cookieExist, transferredPromo, ...data }) =>
        of(this.cookies.setCookies(data)).pipe(
          map(() => SessionActions.setCookiesSuccess({ cookieExist, transferredPromo })),
          catchError(() => of(SessionActions.setCookiesFail()))
        )
      ),
      catchError(err => {
        console.error(err);
        return of(SessionActions.setCookiesFail());
      })
    );
  });

  setCookiesSuccess$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.setCookiesSuccess),
        concatLatestFrom(() => this.store.select(selectUserSessionNewOrder)),
        map(([{ cookieExist, transferredPromo }, data]) => {
          this.router.navigate([Routes.DASHBOARD]);

          if (transferredPromo?.name) {
            this.dialog.open(TransferConfirmationDialogComponent, {
              data: transferredPromo,
              minWidth: 350,
              hasBackdrop: true,
            });
          } else if (cookieExist) {
            // Not shown for Transfers.
            this.dialog.open(NewOrderConfirmationDialogComponent, {
              data,
              minWidth: 350,
              hasBackdrop: true,
            });
          }
        })
      );
    },
    { dispatch: false }
  );

  orderActionFail$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.acceptOrderFail, SessionActions.addNewOrderFail),
        map(({ error }) => {
          this.notify.showError(error);
        })
      );
    },
    { dispatch: false }
  );

  // TODO [Future Task] - Add failed transfer page and navigate to it
  // setCookiesFail

  constructor(
    private actions$: Actions,
    private api: NewOrderApiService,
    private cookies: CookiesHandlingService,
    private dialog: MatDialog,
    private notify: NotificationsService,
    private router: Router,
    private store: Store
  ) {}
}
