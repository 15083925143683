export interface NewOrder {
  id: string; // Order GUID
  promos: OrderPromotion[];
}

export interface OrderPromotion {
  name: string;
}

export type Promotion = OrderPromotion & {
  promoId: string;
  promoCode: number;
  companyName: string;
};

export interface CookieData {
  cookieCode: string;
  expDate: Date;
}
