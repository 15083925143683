<h2 mat-dialog-title>{{ title$ | ngrxPush }}</h2>

<!-- TODO: What is message needed here for? -->
<mat-dialog-content *ngrxLet="message$ as message">
  <vf-new-order-promos-list [promos]="data.promos"></vf-new-order-promos-list>
</mat-dialog-content>

<mat-dialog-actions>
  <vf-button class="w-full m-0" [messageType]="messageTypes.DIALOG_OK_BTN" mat-dialog-close></vf-button>
</mat-dialog-actions>
