import { combineLatest, map, Observable, take } from 'rxjs';

export const camelCaseToSentenceCase = (camelCase: string): string => {
  if (!camelCase) {
    return '';
  }
  // Adds spaces in front of capitalized letters
  const sentenceCase = camelCase?.replace(/([A-Z])/g, ' $1').trim();
  // Capitalizes first character
  return sentenceCase.charAt(0).toUpperCase() + sentenceCase.slice(1);
};

export const getStoreValue = <T>(state: Observable<T>): T => {
  let value!: T;
  state?.pipe(take(1)).subscribe(val => (value = val));
  return value;
};

export const checkLoading = (...args: Observable<boolean>[]): Observable<boolean> =>
  combineLatest(args).pipe(map(val => val.some(loading => loading)));

export const checkLoaded = (...args: Observable<boolean>[]): Observable<boolean> =>
  combineLatest(args).pipe(map(val => val.every(loaded => loaded)));

export const isBoolean = (item: unknown): item is boolean => item !== null && typeof item === 'boolean';
export const isObject = (item: unknown): boolean => item !== null && typeof item === 'object';
export const isString = (item: unknown): item is string => item !== null && typeof item === 'string';
export const isNumeric = (value: string): boolean => !isNaN(+value - parseFloat(value));

export const compareValues = (val1: string | number, val2: string | number): boolean =>
  val1?.toString().toLowerCase() === val2?.toString().toLowerCase();

export const toTitleCase = (str: string): string =>
  str?.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()) ?? '';

export const transformSnakeCase = (str: string | null): string => (str ? toTitleCase(str.replace(/_/g, ' ')) : '');
