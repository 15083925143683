import { EntityStore, ErrorResponse, ListStore } from '@data/models';

export const loadListFunction =
  <T, U extends ListStore<T>>() =>
  (state: U): U => ({
    ...state,
    loading: true,
    loaded: false,
  });

export const loadListSuccessFunction =
  <T, U extends ListStore<T>>() =>
  (state: U, { data }: { data: T[] }): U => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    data,
  });

export const loadListFailFunction =
  <T, U extends ListStore<T>>() =>
  (state: U, { error }: { error: ErrorResponse }): U => ({
    ...state,
    loading: false,
    loaded: false,
    error,
  });

export const loadEntityFunction =
  <T, U extends EntityStore<T>>() =>
  (state: U): U => ({
    ...state,
    loading: true,
    loaded: false,
  });

export const loadEntitySuccessFunction =
  <T, U extends EntityStore<T>>() =>
  (state: U, { data }: { data: T }): U => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    data,
  });

export const loadEntityFailFunction =
  <T, U extends EntityStore<T>>() =>
  (state: U, { error }: { error: ErrorResponse }): U => ({
    ...state,
    loading: false,
    loaded: false,
    error,
  });
