import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { uiMessagesAcceptCookiesParamName } from '@data/constants';
import { UiMessage } from '@data/models';
import { map, Observable } from 'rxjs';
import { CookiesHandlingService } from './cookies-handling.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class UiMessagesApiService {
  private readonly path = 'message';

  constructor(private http: HttpService, private cookies: CookiesHandlingService) {}

  getMessages(token = '', paramName = uiMessagesAcceptCookiesParamName): Observable<UiMessage[]> {
    const data: [string, string] =
      !token && this.cookies.cookiesExist() ? ['cookieCode', this.cookies.getCookies() || ''] : [paramName, token];
    const params = new HttpParams().set(data[0], data[1]);

    return this.http
      .get<{ messages: UiMessage[] }>(`${this.path}/${navigator.language ?? 'en-US'}`, params)
      .pipe(map(({ messages }) => messages));
  }
}
