import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ErrorResponse } from '@data/models';
import { getStoreValue } from '@shared/helpers';
import { UiMessagesService } from './ui-messages.service';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(private messages: UiMessagesService, private snackbar: MatSnackBar) {}

  show(message: string, action = 'Close', config?: MatSnackBarConfig): void {
    const barConfig = { duration: 3000, ...config };
    this.snackbar.open(message, action, barConfig);
  }

  showError(err: HttpErrorResponse, config?: MatSnackBarConfig): void {
    const type = (err as ErrorResponse)?.error?.message;
    const message: string = getStoreValue(this.messages.getMessage(type)) || 'Unexpected Error';
    const barConfig: MatSnackBarConfig = { panelClass: ['error-snackbar'], ...config };

    this.show(message, 'Close', barConfig);
  }
}
