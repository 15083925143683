/* eslint-disable ngrx/prefix-selectors-with-select */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SessionState } from './session.reducer';

export const selectSession = createFeatureSelector<SessionState>('session');

export const selectUserSessionNewOrder = createSelector(selectSession, (state: SessionState) => state.newOrder);

export const selectUserSessionLoading = createSelector(selectSession, (state: SessionState) => state.loading);
