import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { map, Observable } from 'rxjs';

// TODO Do the methods need `withCredentials: true` for the cookies
// Relevant StackOverflow: https://stackoverflow.com/a/35603479
@Injectable({ providedIn: 'root' })
export class HttpService {
  constructor(private http: HttpClient) {}

  get<T>(path: string, params?: HttpParams): Observable<T> {
    return this.http.get<T>(`${environment.apiUrl}${path}`, { params, withCredentials: true });
  }

  post<T, V>(path: string, body: T, params?: HttpParams): Observable<V> {
    return this.http.post<V>(`${environment.apiUrl}${path}`, body, { params, withCredentials: true });
  }

  put<T, V>(path: string, body: T, params?: HttpParams): Observable<V> {
    return this.http.put<V>(`${environment.apiUrl}${path}`, body, { params, withCredentials: true });
  }

  deleteItem<T>(path: string): Observable<T> {
    return this.http.delete<T>(`${environment.apiUrl}${path}`, { withCredentials: true });
  }

  deleteItemWithOptions<T, U>(path: string, body: T): Observable<U> {
    return this.http.request<U>('delete', `${environment.apiUrl}${path}`, { body, withCredentials: true });
  }

  getItems<T>(path: string, params?: HttpParams): Observable<T[]> {
    return this.get<{ items: T[] }>(path, params).pipe(map(res => res?.items ?? []));
  }

  getItem<T>(path: string, params?: HttpParams): Observable<T | null> {
    return this.get<{ item: T }>(path, params).pipe(map(res => res?.item ?? null));
  }
}
