import { NewOrder } from '@data/models';
import { createReducer, on } from '@ngrx/store';

import * as SessionActions from './session.actions';

export interface SessionState {
  loading: boolean;
  loaded: boolean;
  newOrder: NewOrder | null;
  updatingCookies: boolean;
}

export const initialState: SessionState = {
  loading: false,
  loaded: false,
  newOrder: null,
  updatingCookies: false,
};

export const sessionReducer = createReducer(
  initialState,
  on(SessionActions.acceptOrder, SessionActions.addNewOrder, (state): SessionState => ({ ...state, loading: true })),
  on(SessionActions.addNewOrderSuccess, (state, { newOrder }): SessionState => ({ ...state, loading: true, newOrder })),
  on(SessionActions.setCookies, (state): SessionState => ({ ...state, loading: true, loaded: false })),
  on(
    SessionActions.setCookiesSuccess,
    (state): SessionState => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),
  on(
    SessionActions.setCookiesFail,
    SessionActions.acceptOrderFail,
    SessionActions.addNewOrderFail,
    (state): SessionState => ({ ...state, loading: false, loaded: true })
  )
);
