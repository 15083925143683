import { MessageTypes } from '../enums';

export interface ErrorResponse {
  error: {
    message: MessageTypes;
    type: string;
    traceId: string;
    details?: string[];
  };
}

export interface ValidationHint {
  check: (value: string) => boolean;
  rule: string;
}

export interface OnDeactivate {
  modified: boolean;
  cancel(nextRoute?: string): void;
}
