import { Injectable } from '@angular/core';
import { CookieData } from '@data/models';
import { environment } from '@env';
import { CookieService } from 'ngx-cookie-service';

const enum CookiesSameSite {
  LAX = 'Lax',
  STRICT = 'Strict',
}

interface SetCookieOptions {
  expires: number | Date;
  path: string;
  domain: string;
  secure: boolean;
  sameSite: CookiesSameSite;
}

const setCookieOptionsDefaults: Partial<SetCookieOptions> = {
  path: '/',
  domain: environment.domain,
  secure: true,
  sameSite: CookiesSameSite.STRICT,
};

@Injectable({ providedIn: 'root' })
export class CookiesHandlingService {
  private readonly path = 'token';

  constructor(private cookies: CookieService) {}

  cookiesExist(name = this.path): boolean {
    return this.cookies.check(name);
  }

  // TODO Delete Cookies needed?
  // deleteCookie(name: string, )

  getCookies(name = this.path): string {
    return this.cookies.get(name);
  }

  setCookies({ cookieCode, expDate }: CookieData, name = this.path): void {
    this.cookies.set(name, cookieCode, { ...setCookieOptionsDefaults, expires: expDate });

    if (!this.cookiesExist()) {
      throw new Error(
        `CookiesHandlingService::SetCookies() - Failed to set Cookies. Cookies Not Found.
          CookieCode: ${cookieCode}
          ExpirationDate: ${expDate.toString()}`
      );
    }
  }
}
