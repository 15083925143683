import { MessageTypes } from '../enums';

export interface UiMessage {
  message: string;
  messageType: MessageTypes;
}

export type Messages = Record<MessageTypes, string>;

export type MessageReplacementKey = 'promo' | 'promoNum' | 'time' | 'totalPromos';
export type MessageFillinValues = Partial<Record<MessageReplacementKey, string>>;
