import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@app/services';
import { CookieData, NewOrder } from '@data/models';
import { Observable } from 'rxjs';
import { CookiesHandlingService } from './cookies-handling.service';

@Injectable({
  providedIn: 'root',
})
export class NewOrderApiService {
  private readonly path = 'neworder';

  constructor(private http: HttpService, private cookies: CookiesHandlingService) {}

  getNewOrderInfo(orderId: string): Observable<NewOrder> {
    return this.http.get<NewOrder>(`${this.path}/${orderId}`);
  }

  acceptNewOrder(orderId: string, accepted = true): Observable<CookieData> {
    const params = this.cookies.cookiesExist()
      ? new HttpParams().set('cookieCode', this.cookies.getCookies())
      : undefined;

    return this.http.put<null, CookieData>(`${this.path}/${orderId}/${accepted ? 'true' : 'false'}`, null, params);
  }
}
