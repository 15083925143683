import { Messages } from '@data/models';

export const fallbackMessages: Messages = {
  ['Dialog.CancelButton']: 'Cancel',
  ['Dialog.NoButton']: 'No',
  ['Dialog.OkButton']: 'OK',
  ['Dialog.YesButton']: 'Yes',
  ['Error.ExpiredPromo']: 'The promo has been expired.',
  ['Error.InvalidCookieFile']: 'Invalid cookie file.',
  ['Error.InvalidCredentials']: 'Invalid credentials.',
  ['Error.InvalidData']: 'Invalid data provided.',
  ['Error.InvalidExpiredQr']: 'Invalid or expired QR Code.',
  ['Error.InvalidOrder']: 'The order is invalid.',
  ['Error.InvalidPromo']: 'Invalid promo.',
  ['Error.InvalidPromoOrTransferCompleted']: 'Invalid promo or the transfer has been completed.',
  ['Error.InvalidQr']: 'Invalid QR Code.',
  ['Error.OrderAlreadyCompleted']: 'The order has already been completed.',
  ['Error.OrderHasNoPromotions']: 'The order has no promotions.',
  ['Page.AcceptCookie.AcceptButton']: 'Accept Cookies to Continue',
  ['Page.AcceptCookie.DenyButton']: 'Deny Cookies',
  ['Page.AcceptCookie.DenyConfirmation']:
    'By denying cookies the order will be cancelled and a refund issued. Are you sure you would like to continue?',
  ['Page.AcceptCookie.DenyTitle']: 'Are you sure?',
  ['Page.AcceptCookie.ThankYou']:
    'Thank you for your purchase. In order to access your promo(s), you must first accept cookies on your device.',
  ['Page.CookieDenied.Instruction']: 'The order has been cancelled. Please ask the cashier for a refund.',
  ['Page.MyPromos.Dialog.NewOrder.Message']:
    'Thank you for your purchase! The following promotions have been added to your account',
  ['Page.MyPromos.Dialog.NewOrder.Title']: 'Order Accepted',
  ['Page.MyPromos.GetQrCodeButton']: 'Get QR Code',
  ['Page.MyPromos.PromoNumber']: 'Promo {promoNum} of {totalPromos}',
  ['Page.MyPromos.ScanInstruction']: 'Click Show Code and scan at FreeStyle to redeem drinks.',
  ['Page.MyPromos.Title']: 'My Promotions',
  ['Page.MyPromos.TransferButton']: 'Transfer',
  ['Page.MyPromos.TransferCompleted.Message']: 'The promotion {promo} has been added to your promotions.',
  ['Page.MyPromos.TransferCompleted.Title']: 'Transfer Completed',
  ['Page.NotFound.Message']: 'Page Not Found',
  ['Page.NotFound.RedirectButton']: 'Return to Home Page',
  ['Page.NotFound.Title']: 'Page Not Found',
  ['Page.ScanCode.Instruction']: 'Scan at FreeStyle to redeem drinks.',
  ['Page.ScanCode.Time']: 'Time remained: {time}',
  ['Page.ScanCode.Title']: 'Scan Code',
  ['Page.Transfer.Recipient.AcceptButton']: 'Accept Promo',
  ['Page.Transfer.Recipient.CancelButton']: 'Cancel',
  ['Page.Transfer.Recipient.DeniedMessage']: 'Transfer was cancelled.',
  ['Page.Transfer.Recipient.Instruction']: 'A promo has been shared with you. Would you like to accept it?',
  ['Page.Transfer.Recipient.Title']: 'Transfer Promo',
  ['Page.Transfer.Sender.CancelButton']: 'Close',
  ['Page.Transfer.Sender.CopyButton']: 'Copy',
  ['Page.Transfer.Sender.Instruction']: 'Scan the code or share as a link.',
  ['Page.Transfer.Sender.ShareButton']: 'Share',
  ['Page.Transfer.Sender.Title']: 'Transfer Promo',
  ['Web.GenericError']: 'An unexpected error occurred. Please retry in a moment.',
  ['Web.Title']: 'FreeStyle QR Refills',
};
