// Kept getting end of line error from prettier/prettier
// Can't use Arrow Function for Assertion Functions normally (see commented code below for example of work around)
// Closed Github issue on topic: https://github.com/microsoft/TypeScript/issues/34523

export function assertCondition(condition: unknown, message = 'Assertion failure'): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}

export function assertKeyInObject<T extends object>(
  key: string | number | symbol,
  obj: T,
  message = `Assertion Failed - ${String(key)} not in ${typeof obj}`
): asserts key is keyof T {
  assertCondition(key in obj, message);
}

// Work Around to use Arrow Function for Assertion Function
// export const keyInObject: <T>(obj: T, key: string | number | symbol, message?: string) => asserts key is keyof T = (
//   obj,
//   key,
//   message
// ) => {
//   const assert: AssertCondition['assert'] = new AssertCondition().assert;
//   assert(key in obj, message ?? `Assertion Failed - ${String(key)} not in ${typeof obj}`);
// };
