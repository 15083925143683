import { Injectable } from '@angular/core';
import { selectSpecificMessageType } from '@app/store';
import { MessageTypes } from '@data/enums';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UiMessagesService {
  constructor(private store: Store) {}

  getMessage(messageType: MessageTypes): Observable<string> {
    return this.store.select(selectSpecificMessageType(messageType));
  }
}
