import * as fromRouter from '@ngrx/router-store';
import { BaseRouterStoreState, MinimalRouterStateSnapshot } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Params } from '@angular/router';

export const selectRouter = createFeatureSelector<fromRouter.RouterReducerState<BaseRouterStoreState>>('router');

export const selectRouteNestedParams = createSelector(selectRouter, router => {
  let currentRoute = (router?.state as MinimalRouterStateSnapshot)?.root;
  let params: Params = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }
  return params;
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectRouteNestedParam = (param: string) =>
  createSelector(selectRouteNestedParams, params => params?.[param]);

export const {
  selectCurrentRoute,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
} = fromRouter.getRouterSelectors(selectRouter);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectRouteDataByName = (name: string) => createSelector(selectRouteData, data => data?.[name]);
