import { Options } from 'ngx-qrcode-styling';

export const qrCodeBonafontColor = '#ff734d';
export const qrCodeBonafontImagePath = 'assets/img/bonafont_outlined.png';
export const qrCodeCokeColor = '#f44034';
export const qrCodeCokeImagePath = 'assets/img/coke_freestyle.png';

export const qrConfig: Options = {
  type: 'canvas',
  shape: 'square',
  width: 320,
  height: 320,
  margin: 0,
  image: qrCodeCokeImagePath,
  qrOptions: {
    errorCorrectionLevel: 'H',
  },
  imageOptions: {
    hideBackgroundDots: true,
    crossOrigin: 'use-credentials',
    margin: 0,
  },
  dotsOptions: {
    type: 'square',
    color: qrCodeCokeColor,
  },
  cornersSquareOptions: {
    type: 'square',
    color: qrCodeCokeColor,
  },
  backgroundOptions: { color: 'transparent' },
};
